import React from "react";
import { useSelector } from "react-redux";

import { DeleteEventModal } from "./DeleteEvent";
import { DeleteOrganizationModal } from "./DeleteOrganization";

const MODAL_COMPONENTS = {
    "DELETE_EVENT": DeleteEventModal,
    "DELETE_ORGANIZATION": DeleteOrganizationModal
};

export const ModalRoot = () => {
    const modalType = useSelector(state => state.modal.modalType);
    const modalProps = useSelector(state => state.modal.modalProps);

    if (!modalType) return null;

    const SpecificModal = MODAL_COMPONENTS[modalType];
    return <SpecificModal {...modalProps} />;
};
