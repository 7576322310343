import { staffConstants } from "../constants/actionTypes";

const initialState = {
    getStaffSuccess: false,
    getStaffLoading: false,
    getProfileSuccess: false,
    getProfileLoading: false,
    staffList: [],
    profile: null,
    currentPage: 0,
    rowsPerPage: 10,
    orderBy: "organizationName",
    order: "asc",
    updateStaffSuccess: false,
    staffError: false,
};

export default function StaffReducer(state = initialState, action) {
    switch (action.type) {
        case staffConstants.FETCH_STAFF:
            return {
                ...state,
                getStaffSuccess: false,
                getStaffLoading: true,
            };
        case staffConstants.FETCH_STAFF_SUCCESS:
            return {
                ...state,
                getStaffSuccess: true,
                getStaffLoading: false,
                staffList: action.payload
            };
        case staffConstants.FETCH_PROFILE:
            return {
                ...state,
                getProfileSuccess: false,
                getProfileLoading: true,
            };
        case staffConstants.FETCH_PROFILE_SUCCESS:
            return {
                ...state,
                getProfileSuccess: true,
                getProfileLoading: false,
                profile: action.payload
            };
        case staffConstants.ADD_STAFF:
            return {
                ...state,
                updateStaffSuccess: false,
                staffError: false,
            };
        case staffConstants.UPDATE_STAFF_SUCCESS:
            return {
                ...state,
                updateStaffSuccess: true,
            };
        case staffConstants.CHANGE_STAFF_CURRENT_PAGE:
            return {
                ...state, currentPage: action.payload
            };
        case staffConstants.CHANGE_STAFF_ROWS_PER_PAGE:
            return {
                ...state, currentPage: 0, rowsPerPage: action.payload
            };
        case staffConstants.CHANGE_STAFF_ORDER:
            return {
                ...state, order: action.payload
            };
        case staffConstants.CHANGE_STAFF_ORDERBY:
            return {
                ...state, orderBy: action.payload
            };
        case staffConstants.ERROR_STAFF:
            return {
                ...state, staffError: action.payload
            }
        default:
            return state;
    }
};