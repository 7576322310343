import {store} from "../base";


async function convertImgUrlToBlob(imgUrl) {
  // To be allowed to fetch the Cloud Firestore image URL we need to setup a Cross origin policy:
  // It's already done, but if you get blocked for some reason, please read the docs:
  // https://firebase.google.com/docs/storage/web/download-files#cors_configuration
  const response = await fetch(imgUrl, {mode: "cors"});
  return await response.blob();
}


async function getLogoAbsoluteURL(logoFileName, cloudStorageFolder) {
  return await store.ref().child(`images/${cloudStorageFolder}/logo/${logoFileName}`).getDownloadURL();
}


async function getImageAbsoluteURL(imageFileName, cloudStorageFolder) {
  return await store.ref().child(`images/${cloudStorageFolder}/${imageFileName}`).getDownloadURL();
}


async function getLogoAndImagesFromCloudFirestore(logoFileName, imagesFileNames, cloudFirestoreFolder) {
  const [logo, images] = await Promise.all([
    getImageFromCloudFirestore(logoFileName, cloudFirestoreFolder, true),
    Promise.all(imagesFileNames.map(async imgName => getImageFromCloudFirestore(imgName, cloudFirestoreFolder, false)))
  ]);
  return [
    logo ? [logo] : [], // ensure logo is always an array
    images ? images : []
  ];
}


async function getImageFromCloudFirestore(imageName, cloudFirestoreFolder, isLogo) {
  // Example function params:
  // imageName = "l07ej44o.png",
  // cloudFirestoreFolder = "event/NJ9LhoRUBgafYVA8Zxnq",
  // isLogo = true
  try {
    const absoluteUrl = isLogo
      ? await getLogoAbsoluteURL(imageName, cloudFirestoreFolder)
      : await getImageAbsoluteURL(imageName, cloudFirestoreFolder);
    return {
      name: imageName,
      url: absoluteUrl,
      isStorage: true,
      isDelete: false,
    };
  } catch (err) {
    console.log("Failed to get image of type: " + (isLogo ? "logo" : "images gallery"));
    console.log(err);
  }
}


async function getImageFileBlob(src, imageType, cloudStorageFolder) {
  // firebase cloud storage requires a blob or file to save images,
  // so we need to have the valid image format before saving anything.
  // Since we might have image filenames, or react state objects or Blobs
  // we need to ensure we always convert images data to blobs.
  
  // The complexity arrises because in the React components, the values are inconsistent for logo and images.
  // That's why there are condition checks to handle the different possible kind of src value we received.
  if (src instanceof Blob)
    return src;
  
  if (src.url)
    return await convertImgUrlToBlob(src.url);
  
  if (typeof src === "string") {
    const absoluteImgUrlInCloudStorage = imageType === "logo"
      ? await getLogoAbsoluteURL(src, cloudStorageFolder)
      : await getImageAbsoluteURL(src, cloudStorageFolder);
    return await convertImgUrlToBlob(absoluteImgUrlInCloudStorage);
  }
}


export {getLogoAndImagesFromCloudFirestore, getImageFromCloudFirestore, getImageFileBlob};
