import {createStore, applyMiddleware} from "redux";
//import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";

import rootReducer from "./reducers/rootReducer";
import { verifyAuth } from "./actions/authAction";

// export default function configureStore() {
//     const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk)));
//     store.dispatch(verifyAuth());
//     return store;
// }

export default function configureStore() {
    const store = createStore(rootReducer, applyMiddleware(ReduxThunk));
    store.dispatch(verifyAuth());
    return store;
}
