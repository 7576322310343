import {reportConstants} from "../constants/actionTypes";


const initialState = {
  
  organizationList: [],
  isOrganizationLoading: false,
  getOrganizationSuccess: false,
  orgCurrentPage: 0,
  orgRowsPerPage: 10,
  orgOrder: "asc",
  orgOrderBy: "name",
  
  eventList: [],
  isEventLoading: false,
  getEventSuccess: false,
  eventCurrentPage: 0,
  eventRowsPerPage: 10,
  eventOrder: "asc",
  eventOrderBy: "name",
  
  volunteerOffersList: [],
  isVolunteerOffersLoading: false,
  getVolunteerOffersSuccess: false,
  volunteerOffersCurrentPage: 0,
  volunteerOffersRowsPerPage: 10,
  volunteerOffersOrder: "asc",
  volunteerOffersOrderBy: "name",
  
  servicesList: [],
  isServicesLoading: false,
  getServicesSuccess: false,
  servicesCurrentPage: 0,
  servicesRowsPerPage: 10,
  servicesOrder: "asc",
  servicesOrderBy: "name",
  
  staffReportList: [],
  isStaffReportLoading: false,
  getStaffReportSuccess: false,
  staffReportCurrentPage: 0,
  staffReportRowsPerPage: 10,
  staffReportOrder: "asc",
  staffReportOrderBy: "organizationName",
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    
    /*  **********    Organizations -action Types && SetStates  *********   */
    case reportConstants.FETCH_ORGANIZATION:
      return {
        ...state,
        isOrganizationLoading: true,
        getOrganizationSuccess: false,
        organizationList: []
      };
    case reportConstants.FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isOrganizationLoading: false,
        getOrganizationSuccess: true,
        organizationList: action.payload
      };
    case reportConstants.CHANGE_ORGANIZATION_CURRENT_PAGE:
      return {
        ...state, orgCurrentPage: action.payload
      };
    case reportConstants.CHANGE_ORGANIZATION_ROWS_PER_PAGE:
      return {
        ...state, orgCurrentPage: 0, orgRowsPerPage: action.payload
      };
    case reportConstants.CHANGE_ORGANIZATION_ORDER:
      return {
        ...state, orgOrder: action.payload
      };
    case reportConstants.CHANGE_ORGANIZATION_ORDERBY:
      return {
        ...state, orgOrderBy: action.payload
      };
    
    /*  **********    Events -action Types && SetStates  *********   */
    case reportConstants.FETCH_EVENT:
      return {
        ...state,
        isEventLoading: true,
        getEventSuccess: false,
        eventList: []
      };
    case reportConstants.FETCH_EVENT_SUCCESS:
      return {
        ...state,
        isEventLoading: false,
        getEventSuccess: true,
        eventList: action.payload
      };
    case reportConstants.CHANGE_EVENT_CURRENT_PAGE:
      return {
        ...state, eventCurrentPage: action.payload
      };
    case reportConstants.CHANGE_EVENT_ROWS_PER_PAGE:
      return {
        ...state, eventCurrentPage: 0, eventRowsPerPage: action.payload
      };
    case reportConstants.CHANGE_EVENT_ORDER:
      return {
        ...state, eventOrder: action.payload
      };
    case reportConstants.CHANGE_EVENT_ORDERBY:
      return {
        ...state, eventOrderBy: action.payload
      };
    
    /*  **********    Volunteer offers -action Types && SetStates  *********   */
    case reportConstants.FETCH_VOLUNTEER_OFFERS:
      return {
        ...state,
        isVolunteerOffersLoading: true,
        getVolunteerOffersSuccess: false,
        volunteerOffersList: []
      };
    case reportConstants.FETCH_VOLUNTEER_OFFERS_SUCCESS:
      return {
        ...state,
        isVolunteerOffersLoading: false,
        getVolunteerOffersSuccess: true,
        volunteerOffersList: action.payload
      };
    case reportConstants.CHANGE_VOLUNTEER_OFFERS_CURRENT_PAGE:
      return {
        ...state, volunteerOffersCurrentPage: action.payload
      };
    case reportConstants.CHANGE_VOLUNTEER_OFFERS_ROWS_PER_PAGE:
      return {
        ...state, volunteerOffersCurrentPage: 0, volunteerOffersRowsPerPage: action.payload
      };
    case reportConstants.CHANGE_VOLUNTEER_OFFERS_ORDER:
      return {
        ...state, volunteerOffersOrder: action.payload
      };
    case reportConstants.CHANGE_VOLUNTEER_OFFERS_ORDERBY:
      return {
        ...state, volunteerOffersOrderBy: action.payload
      };
    
    /*  **********    Services -action Types && SetStates  *********   */
    case reportConstants.FETCH_SERVICE_REPORTS:
      return {
        ...state,
        isServicesLoading: true,
        getServicesSuccess: false,
        servicesList: []
      };
    case reportConstants.FETCH_SERVICE_REPORTS_SUCCESS:
      return {
        ...state,
        isServicesLoading: false,
        getServicesSuccess: true,
        servicesList: action.payload
      };
    case reportConstants.CHANGE_SERVICE_REPORTS_CURRENT_PAGE:
      return {
        ...state, servicesCurrentPage: action.payload
      };
    case reportConstants.CHANGE_SERVICE_REPORTS_ROWS_PER_PAGE:
      return {
        ...state, servicesCurrentPage: 0, servicesRowsPerPage: action.payload
      };
    case reportConstants.CHANGE_SERVICE_REPORTS_ORDER:
      return {
        ...state, servicesOrder: action.payload
      };
    case reportConstants.CHANGE_SERVICE_REPORTS_ORDERBY:
      return {
        ...state, servicesOrderBy: action.payload
      };
    
    /*  **********    Staff -action Types && SetStates  *********   */
    case reportConstants.FETCH_STAFF_REPORT:
      return {
        ...state,
        isStaffReportLoading: true,
        getStaffReportSuccess: false,
        staffReportList: []
      };
    case reportConstants.FETCH_STAFF_REPORT_SUCCESS:
      return {
        ...state,
        isStaffReportLoading: false,
        getStaffReportSuccess: true,
        staffReportList: action.payload
      };
    case reportConstants.CHANGE_STAFF_REPORT_CURRENT_PAGE:
      return {
        ...state, staffReportCurrentPage: action.payload
      };
    case reportConstants.CHANGE_STAFF_REPORT_ROWS_PER_PAGE:
      return {
        ...state, staffReportCurrentPage: 0, staffReportRowsPerPage: action.payload
      };
    case reportConstants.CHANGE_STAFF_REPORT_ORDER:
      return {
        ...state, staffReportOrder: action.payload
      };
    case reportConstants.CHANGE_STAFF_REPORT_ORDERBY:
      return {
        ...state, staffReportOrderBy: action.payload
      };
    
    default:
      return state;
  }
}
