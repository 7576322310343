import React from "react";
import { Provider } from "react-redux";

import configureStore from "./redux/store";
import { AppRoute } from "./routes/AppRoute";

const store = configureStore();

const App = () => {
    return (
        <Provider store={store}>
            <AppRoute/>
        </Provider>
    );
};

export default App;
