import {db} from "../../base";
import {getFirestoreCollection} from "../../helpers/firestoreHelpers";


async function createNotification(
  {organizationId, category, categoryId, name}, // object destructuring
  {eventId = "", volunteeringId = "", serviceId = ""}, // object destructuring
  notificationModel
) {
  
  const notification = {
    type: notificationModel.type,
    title: notificationModel.title,
    message: notificationModel.message,
    date: new Date(),
    organizationId,
    categoryId: category === undefined ? categoryId : category, // handle inconsistent data in our database
    // dynamic:
    eventId: "",
    eventName: "",
    volunteeringId: "",
    volunteeringName: "",
    serviceId: "",
    serviceName: "",
  };
  
  if (eventId) {
    notification.eventId = eventId;
    notification.eventName = name.fr || name.en || name.es || name.ar;
  } else if (volunteeringId) {
    notification.volunteeringId = volunteeringId;
    notification.volunteeringName = name.fr || name.en || name.es || name.ar;
  } else if (serviceId) {
    notification.serviceId = serviceId;
    notification.serviceName = name.fr || name.en || name.es || name.ar;
  }
  
  try {
    await db.collection("Notification").add(notification);
    console.log("Added Notification : " + notificationModel.type);
  } catch (error) {
    console.error("Create Notification error: ", error);
  }
  
}


async function getNotificationsByType({eventId = "", volunteeringId = "", serviceId = ""}) {
  
  let queryFilters;
  
  // define by what kind of filter we get the data in Firestore
  if (eventId) {
    queryFilters = [["eventId", "==", eventId]];
  } else if (volunteeringId) {
    queryFilters = [["volunteeringId", "==", volunteeringId]];
  } else if (serviceId) {
    queryFilters = [["serviceId", "==", serviceId]];
  }
  
  // the complete filtered data set
  const data = await getFirestoreCollection(
    "Notification",
    queryFilters,
    "date"
  );
  
  // the final notifications data starting with most recent dates on top.
  const notifications = data.reverse().map(notification => ({
    title: notification.title,
    message: notification.message,
    date: notification.date.toDate()
  }));
  
  return notifications;
}


export {createNotification, getNotificationsByType};
