import {serviceConstants} from "../constants/actionTypes";

const initialState = {
  getServiceSuccess: false,
  serviceLoading: true,
  servicesList: [],
  currentPage: 0,
  rowsPerPage: 10,
  orderBy: "name",
  order: "asc",
  categories: [], // maybe not needed
  viewId: undefined, // maybe not needed
};

export default function ServiceReducer(state = initialState, action) {
  switch (action.type) {
    case serviceConstants.FETCH_SERVICE:
      return {
        ...state,
        getServiceSuccess: false,
        serviceLoading: true,
        servicesList: [],
      };
    case serviceConstants.FETCH_SERVICE_SUCCESS:
      return {
        ...state,
        getServiceSuccess: true,
        serviceLoading: false,
        servicesList: action.payload
      };
    case serviceConstants.SAVE_SERVICE:
      return {
        ...state,
        serviceLoading: true
      };
    case serviceConstants.CHANGE_SERVICE_CURRENT_PAGE:
      return {
        ...state, currentPage: action.payload
      };
    case serviceConstants.CHANGE_SERVICE_ROWS_PER_PAGE:
      return {
        ...state, currentPage: 0, rowsPerPage: action.payload
      };
    case serviceConstants.CHANGE_SERVICE_ORDER:
      return {
        ...state, order: action.payload
      };
    case serviceConstants.CHANGE_SERVICE_ORDERBY:
      return {
        ...state, orderBy: action.payload
      };
    case serviceConstants.GET_CATEGORIES_SUCCESS: // maybe not needed
      return {...state, categories: action.payload};
    case serviceConstants.ONVIEW_SERVICE:
      return {...state, viewId: action.payload};
    case serviceConstants.ONVIEW_DONE_SERVICE:
      return {...state, viewId: undefined};
    default:
      return state;
  }
}
