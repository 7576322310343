import {basketConstants} from "../constants/actionTypes";

const initialState = {
  getBasketSuccess: false,
  getBasketLoading: false,
  basketList: [],
  basketCurrentPage: 0,
  basketRowsPerPage: 10,
  basketOrder: "asc",
  basketOrderBy: "name",
  
  getReferralsSuccess: false,
  getReferralsLoading: false,
  referralsList: [],
  referralsCurrentPage: 0,
  referralsRowsPerPage: 10,
  referralsOrder: "asc",
  referralsOrderBy: "name",
  staff: [],
};

export default function basketReducer(state = initialState, action) {
  switch (action.type) {
    case basketConstants.FETCH_BASKET:
      return {
        ...state,
        getBasketSuccess: false,
        getBasketLoading: true,
        basketList: [],
      };
    case basketConstants.FETCH_BASKET_SUCCESS:
      return {
        ...state,
        getBasketSuccess: true,
        getBasketLoading: false,
        basketList: action.payload
      };
    case basketConstants.FETCH_REFERRALS:
      return {
        ...state,
        getReferralsSuccess: false,
        getReferralsLoading: true,
        referralsList: []
      };
    case basketConstants.FETCH_REFERRALS_SUCCESS:
      return {
        ...state,
        getReferralsSuccess: true,
        getReferralsLoading: false,
        referralsList: action.payload
      };
    case basketConstants.CHANGE_BASKET_CURRENT_PAGE:
      return {
        ...state,
        basketCurrentPage: action.payload
      };
    case basketConstants.CHANGE_BASKET_ROWS_PER_PAGE:
      return {
        ...state,
        basketCurrentPage: 0,
        basketRowsPerPage: action.payload
      };
    case basketConstants.CHANGE_BASKET_ORDER:
      return {
        ...state,
        basketOrder: action.payload
      };
    case basketConstants.CHANGE_BASKET_ORDERBY:
      return {
        ...state,
        basketOrderBy: action.payload
      };
    case basketConstants.CHANGE_REFERRALS_CURRENT_PAGE:
      return {
        ...state,
        referralsCurrentPage: action.payload
      };
    case basketConstants.CHANGE_REFERRALS_ROWS_PER_PAGE:
      return {
        ...state,
        referralsCurrentPage: 0,
        referralsRowsPerPage: action.payload
      };
    case basketConstants.CHANGE_REFERRALS_ORDER:
      return {
        ...state,
        referralsOrder: action.payload
      };
    case basketConstants.CHANGE_REFERRALS_ORDERBY:
      return {
        ...state,
        referralsOrderBy: action.payload
      };
    case basketConstants.GET_EVENTSTAFF_REQUEST:
      return {...state, staff: []};
    case basketConstants.GET_EVENTSTAFF_SUCCESS:
      return {...state, staff: action.payload};
    default:
      return state;
  }
}