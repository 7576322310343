import i18n from "i18next";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

const i18nextOptions = {
  lng: "Fr",
  fallbackLng: "En",
  ns: "translations",
  defaultNS: "translations",
  backend: {
    loadPath: "/assets/{{ns}}/{{lng}}.json"
  },
  debug: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    useSuspense: false
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(i18nextOptions);

export default i18n;