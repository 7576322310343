import React from "react";
import {useLocation} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useDispatch, useSelector} from "react-redux";

import {useTranslation} from "react-i18next";

import {deleteEvent} from "../../../redux/actions/eventActions";
import {modalActions} from "../../../redux/actions/modalAction";

export const DeleteEventModal = ({event}) => {
  
  const dispatch = useDispatch();
  const filterOrganizationId = useSelector(state => state.event.filterOrganizationId);
  
  const location = useLocation();
  const isMyEventsUrl = location.pathname === "/my-events";
  
  const {t} = useTranslation();
  
  const handleDelete = () => {
    if (isMyEventsUrl) {
      const myOrganizationId = event.organizationId;
      dispatch(deleteEvent(event, myOrganizationId));
    } else {
      dispatch(deleteEvent(event, filterOrganizationId));
    }
  };
  
  const handleClose = () => {
    dispatch(modalActions.showModal());
  };
  
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Êtes-vous sûr de vouloir supprimer cet élément?"}</DialogTitle>
        <DialogActions style={{marginBottom: "6px"}}>
          <Button onClick={handleDelete} color="primary" variant="contained">
            {t("agree")}
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t("disagree")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
