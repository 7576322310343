function isAuthenticated(auth) {
  // An Authenticated Account is a valid Firebase Auth Account.
  return Boolean(auth && typeof auth === "object");
}

const hasPermissionTo = {
  manageStaff: (auth) => Boolean(isAdmin(auth) || (isAuthenticated(auth) && auth.manageStaff === "1")),
  updateOrganizationProfile: (auth) => Boolean(isAdmin(auth) || (isAuthenticated(auth) && auth.updateOrganizationProfile === "1")),
  updateOrganizationEvent: (auth) => Boolean(isAdmin(auth) || (isAuthenticated(auth) && auth.updateOrganizationEvent === "1")),
  updateVolunteerOpportunity: (auth) => Boolean(isAdmin(auth) || (isAuthenticated(auth) && auth.updateVolunteerOpportunity === "1")),
  updateOrganizationService: (auth) => Boolean(isAdmin(auth) || (isAuthenticated(auth) && auth.updateOrganizationService === "1" || auth.updateOrganizationService === undefined && auth.updateOrganizationEvent === "1")),
};

function isAdmin(auth) {
  // An Admin account is an authenticated Firebase Account that has no organizationId specified.
  // (Because it was manually created through the Firebase Project's web console).
  // This type of account has all permissions.
  return Boolean(isAuthenticated(auth) && auth.organizationId === "");
}

function dataBelongsToUserOrganization(auth, organizationId) {
  // Data belongs to the Authenticated Account when it was created by their own organization,
  // or they are an Admin with all permissions.
  return Boolean(isAdmin(auth) || (organizationId && auth.organizationId === organizationId));
}

function isAuthorizedToManageStaff(auth) {
  return hasPermissionTo.manageStaff(auth);
}

function isAuthorizedToEditOrgEvents(auth) {
  return hasPermissionTo.updateOrganizationEvent(auth);
}

function isAuthorizedToEditOrgProfiles(auth) {
  return hasPermissionTo.updateOrganizationProfile(auth);
}

function isAuthorizedToEditVolunteeringOffers(auth) {
  return hasPermissionTo.updateVolunteerOpportunity(auth);
}

function isAuthorizedToEditServices(auth) {
  return hasPermissionTo.updateOrganizationService(auth);
}

export {
  isAdmin,
  isAuthorizedToManageStaff,
  isAuthorizedToEditOrgEvents,
  isAuthorizedToEditOrgProfiles,
  isAuthorizedToEditVolunteeringOffers,
  isAuthorizedToEditServices,
  dataBelongsToUserOrganization
};
