import {authConstants} from "../constants/actionTypes";
import {auth} from "../../base";
import {getFirebaseDocumentById, getFirestoreCollection} from "../../helpers/firestoreHelpers";

const loginRequest = () => ({type: authConstants.LOGIN_REQUEST});
const loginSuccess = (user) => {
  return {
    type: authConstants.LOGIN_SUCCESS,
    payload: {
      name: user.displayName,
      email: user.email,
      uid: user.uid,
      staffId: user.staffId,
      organizationId: user.organizationId,
      manageStaff: user.manageStaff,
      updateOrganizationEvent: user.updateOrganizationEvent,
      updateOrganizationProfile: user.updateOrganizationProfile,
      updateVolunteerOpportunity: user.updateVolunteerOpportunity,
      location: user.location,
    }
  };
};
const loginFailure = (error) => {
  return {
    type: authConstants.LOGIN_FAILURE,
    payload: error
  };
};

//const logOutRequest = () => ({type: authConstants.LOGOUT_REQUEST});
const logOutSuccess = () => ({type: authConstants.LOGOUT_SUCCESS});
const logOutFailure = () => ({type: authConstants.LOGOUT_FAILURE});

const verifyRequest = () => ({type: authConstants.VERIFY_REQUEST});
// const verifySuccess = () => ({type: authConstants.VERIFY_SUCCESS});

const forgotPasswordSuccess = () => ({
  type: authConstants.FORGOT_PASSWORD_SUCCESS,
  payload: "Un lien de réinitialisation du mot de passe a été envoyé à votre courriel."
});//"Courriel envoyé. Veuillez vérifier votre courrel." })
const forgotPasswordError = () => ({
  type: authConstants.FORGOT_PASSWORD_FAILED,
  payload: "L'adresse courriel n'est pas dans nos dossiers."
});

export const signIn = ({email, password}) => async dispatch => {
  dispatch(loginRequest());
  try {
    const firebaseAuthResponse = await auth.signInWithEmailAndPassword(email, password);
    await processUserLoginAttempt(firebaseAuthResponse.user, dispatch);
  } catch (err) {
    dispatch(loginFailure("Informations d'identification non valides."));
    console.error(err);
  }
};

export const logOut = () => dispatch => {
  return auth
    .signOut()
    .then(() => dispatch(logOutSuccess()))
    .catch(() => dispatch(logOutFailure()));
};

export const verifyAuth = () => dispatch => {
  dispatch(verifyRequest());
  return auth.onAuthStateChanged(async (firebaseUser) => {
    await processUserLoginAttempt(firebaseUser, dispatch);
  });
};

export const forgotPassword = (email) => dispatch => {
  auth.languageCode = "fr";
  return auth
    .sendPasswordResetEmail(email)
    .then(function () {
      dispatch(forgotPasswordSuccess());
      //alert('Courriel envoyé. Veuillez vérifier votre courrel.')
    }).catch(function (err) {
      dispatch(forgotPasswordError());
      //alert("Les courriel n'est pas dans nos dossiers.")
      console.log(err);
    });
};


async function getValidFirebaseAuthUser(firebaseUser) {
  let error = null;
  try {
    const staff = (await getFirestoreCollection("Staff", [["authUID", "==", firebaseUser.uid]]))[0];
    const [isAllowedToLogin, isOrgActive, isOrgPending] = await shouldAllowLogin(staff);
    if (isAllowedToLogin) {
      Object.assign(firebaseUser, {
        staffId: staff ? staff.id : "",
        organizationId: staff ? staff.organizationId : "",
        manageStaff: staff ? staff.permission.manageStaff : "0",
        updateOrganizationEvent: staff ? staff.permission.updateOrganizationEvent : "0",
        updateOrganizationProfile: staff ? staff.permission.updateOrganizationProfile : "0",
        updateVolunteerOpportunity: staff ? staff.permission.updateVolunteerOpportunity : "0",
        location: staff ? staff.location : [],
      });
    } else {
      firebaseUser = null;
      error = isOrgPending
        ? "Cet organisme est en attente d'approbation."
        : isOrgActive
          ? "Ce compte n'est pas activé."
          : "Cet organisme n'est pas activé.";
    }
    
  } catch (err) {
    firebaseUser = null;
    error = "Informations d'identification non valides.";
    console.log(err);
  }
  
  return [firebaseUser, error];
}


async function processUserLoginAttempt(firebaseUser, dispatch) {
  if (!firebaseUser) {
    dispatch(loginFailure(""));
    return;
  }
  
  const [validFirebaseUser, error] = await getValidFirebaseAuthUser(firebaseUser);
  if (!error && validFirebaseUser) {
    
    // Allow the login to complete with the valid user:
    dispatch(loginSuccess(validFirebaseUser));
    
  } else {
    
    // Disallow the user login
    console.error(error);
    if (window.location.pathname === "/login") {
      dispatch(loginFailure(error));
    } else {
      dispatch(logOut());
    }
  }
}


async function shouldAllowLogin(staff) {
  const isAdminUser = Boolean(staff === undefined);
  if (isAdminUser) {
    return [true, true, false];
  } else {
    const isStaffAccountEnabled = Boolean(staff.isEnable);
    
    const getOrganizationActiveStatus = async (organizationId) => {
      const organization = await getFirebaseDocumentById("Organization", organizationId);
      return [
        organization.isActive,
        organization.isPending
      ];
    };
    const [isOrganizationActive, isOrganizationPending] = await getOrganizationActiveStatus(staff.organizationId);
    return [
      Boolean(isOrganizationActive && !isOrganizationPending && isStaffAccountEnabled),
      isOrganizationActive,
      isOrganizationPending
    ];
  }
}
