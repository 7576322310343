import {orgConstants} from "../constants/actionTypes";

const initialState = {
  listOrganization: [],
  currentOrganization: null,
  organizationError: null,
  orgLoading: false,
  categories: [],
  currentPage: 0,
  rowsPerPage: 10,
  order: "asc",
  orderBy: "name.fr",
  searchText: "",
  viewId: undefined,
};

export default function organizationReducer(state = initialState, action) {
  switch (action.type) {
    case orgConstants.GET_LIST_ORGANIZATIONS:
      return {
        ...state,
        listOrganization: [],
        orgLoading: true
      };
    case orgConstants.GET_LIST_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        listOrganization: action.payload,
        orgLoading: false
      };
    
    case orgConstants.GET_LIST_ACTIVE_ORGANIZATIONS:
      return {
        ...state,
        listOrganization: [],
        orgLoading: true
      };
    case orgConstants.GET_LIST_ACTIVE_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        listOrganization: action.payload,
        orgLoading: false
      };
    
    case orgConstants.CREATE_ORGANIZATION:
      return {
        ...state
      };
    case orgConstants.GET_ORGANIZATION_BY_ID_SUCCESS:
      return {
        ...state,
        currentOrganization: action.payload,
        organizationError: null
      };
    case orgConstants.GET_ORGANIZATION_BY_ID_FAILURE:
      return {
        ...state,
        currentOrganization: null,
        organizationError: "Organization not found"
      };
    case orgConstants.SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.payload
      };
    case orgConstants.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload
      };
    case orgConstants.CHANGE_CURRENT_PAGE:
      return {
        ...state, currentPage: action.payload
      };
    case orgConstants.CHANGE_ROWS_PER_PAGE:
      return {
        ...state, currentPage: 0, rowsPerPage: action.payload
      };
    case orgConstants.CHANGE_ORDER:
      return {
        ...state, order: action.payload
      };
    case orgConstants.CHANGE_ORDERBY:
      return {
        ...state, orderBy: action.payload
      };
    case orgConstants.SEARCH_ORGANIZATION:
      return {
        ...state, searchText: action.payload
      };
    case orgConstants.ONVIEW_ORGANIZATION:
      return {
        ...state, viewId: action.payload
      };
    case orgConstants.ONVIEW_DONE_ORGANIZATION:
      return {
        ...state, viewId: undefined
      };
    default:
      return state;
  }
}
