import { eventConstants, modalConstants, orgConstants } from "../constants/actionTypes";

const initialState = {
    modalType: null,
    modalProps: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case modalConstants.SHOW_MODAL:
            return {
                modalType: action.payload.modalType,
                modalProps: action.payload.modalProps
            };
        case modalConstants.CLOSE_MODAL:
            return {
                initialState
            };
        case eventConstants.DELETE_EVENT_SUCCESS:
            return {
                modalProps: {}, modalType: null
            };
        case orgConstants.DELETE_ORGANIZATION_SUCCESS:
            return {
                modalProps: {}, modalType: null
            };
        default:
            return state;
    }
};
