export class OrganizationModel {
  constructor(
    address,
    category,
    description,
    images,
    formEmail,
    receiveForm,
    extension,
    facebookPageId,
    facebook, //Page URL
    isPending,
    isActive,
    jobOffers, // page URL
    languages,
    location, //array of numbers [-73.505559, 45.641611]
    logo,
    name,
    offeredServices,
    phone,
    schedule,
    suiteOffice,
    updatedDate,
    volunteerOffers, //Page URL
    website,
  ) {
    this.address = address;
    this.category = category;
    this.description = description;
    
    this.images = images;
    this.formEmail = formEmail;
    this.receiveForm = receiveForm;
    
    this.extension = extension;
    
    this.facebookPageId = facebookPageId;
    this.facebook = facebook;
    this.isPending = isPending;
    this.isActive = isActive;
    this.jobOffers = jobOffers;
    this.languages = languages;
    
    this.location = location;
    this.logo = logo;
    this.name = name;
    
    this.offeredServices = offeredServices;
    this.phone = phone;
    this.schedule = schedule;
    
    this.suiteOffice = suiteOffice;
    this.updatedDate = updatedDate;
    this.volunteerOffers = volunteerOffers;
    this.website = website;
  }
  
  toString() {
    return this.name;
  }
}

// Firestore data converter
export const OrganizationConverter = {
  toFirestore: function (model) {
    return {
      address: model.address,
      category: model.category,
      description: model.description,
      
      images: model.images,
      formEmail: model.formEmail,
      receiveForm: model.receiveForm,
      
      extension: model.extension,
      
      facebookPageId: model.facebookPageId,
      facebook: model.facebook, //Page URL
      isPending: model.isPending,
      isActive: model.isActive,
      jobOffers: model.jobOffers, // page URL
      languages: model.languages,
      
      location: model.location, //array of numbers [-73.505559, 45.641611]
      logo: model.logo,
      name: model.name,
      
      offeredServices: model.offeredServices,
      phone: model.phone,
      schedule: model.schedule,
      
      suiteOffice: model.suiteOffice,
      updatedDate: model.updatedDate,
      volunteerOffers: model.volunteerOffers, //Page URL
      website: model.website,
      
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return new OrganizationModel(
      data.address,
      data.category,
      data.description,
      
      data.images,
      data.formEmail,
      data.receiveForm,
      
      data.extension,
      
      data.facebookPageId,
      data.facebook, //Page URL
      data.isPending,
      data.isActive,
      data.jobOffers, // page URL
      data.languages,
      
      data.location, //array of numbers [-73.505559, 45.641611]
      data.logo,
      data.name,
      
      data.offeredServices,
      data.phone,
      data.schedule,
      
      data.suiteOffice,
      data.updatedDate,
      data.volunteerOffers, //Page URL
      data.website,
    );
  }
};
