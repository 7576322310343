import React from "react";
import {CircularProgress} from "@material-ui/core";


function LoadingOverlay({infoText = ""}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "50%",
        right: "0",
        gap: "2em",
      }}
    >
      <p style={{width: "280px", textAlign: "center"}}>{infoText ? infoText : "Chargement en cours"}...</p>
      <CircularProgress color="primary" />
    </div>
  );
}


function FormFieldLoadingAnimation({infoText = ""}) {
  return (
    <div style={{
      width: "100%",
      minWidth: "250px",
      padding: "1.8em",
      display: "flex",
      alignItems: "center",
      gap: ".5em"
    }}>
      <CircularProgress color="primary" size="1em" />
      {infoText}
    </div>
  
  );
}


export default LoadingOverlay;
export {FormFieldLoadingAnimation};