import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

// ensure translations are loaded for every component that will use {t} = useTranslation()
import "./i18n";

ReactDOM.render(<App />, document.getElementById("root"));

