import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export const PrivateRoute = ({component: Component, ...rest}) => {
    const isAuthorized = useSelector(state => state.auth.isAuthenticated);
    return (
        <Route {...rest} render={props => (
            isAuthorized ?
                <Component {...props} />
                : <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
        )}
        />
    );
};

