import { modalConstants } from "../constants/actionTypes";

export const modalActions = {
    showModal,
    hideModal
};

function showModal(modalProps, modalType) {
    return {
        type: modalConstants.SHOW_MODAL,
        payload: {
            modalProps,
            modalType
        },
    };
}

function hideModal() {
    return {
        type: modalConstants.CLOSE_MODAL,
    };
}
