import {volunteerConstants} from "../constants/actionTypes";

const initialState = {
  getVolunteerSuccess: false,
  volunteerLoading: true,
  volunteeringOffersList: [],
  currentPage: 0,
  rowsPerPage: 10,
  orderBy: "name",
  order: "asc",
  categories: [], // maybe not needed
  viewId: undefined, // maybe not needed
};

export default function VolunteerReducer(state = initialState, action) {
  switch (action.type) {
    case volunteerConstants.FETCH_VOLUNTEER:
      return {
        ...state,
        getVolunteerSuccess: false,
        volunteerLoading: true,
        volunteeringOffersList: [],
      };
    case volunteerConstants.FETCH_VOLUNTEER_SUCCESS:
      return {
        ...state,
        getVolunteerSuccess: true,
        volunteerLoading: false,
        volunteeringOffersList: action.payload
      };
    case volunteerConstants.SAVE_VOLUNTEER:
      return {
        ...state,
        volunteerLoading: true
      };
    case volunteerConstants.CHANGE_VOLUNTEER_CURRENT_PAGE:
      return {
        ...state, currentPage: action.payload
      };
    case volunteerConstants.CHANGE_VOLUNTEER_ROWS_PER_PAGE:
      return {
        ...state, currentPage: 0, rowsPerPage: action.payload
      };
    case volunteerConstants.CHANGE_VOLUNTEER_ORDER:
      return {
        ...state, order: action.payload
      };
    case volunteerConstants.CHANGE_VOLUNTEER_ORDERBY:
      return {
        ...state, orderBy: action.payload
      };
    case volunteerConstants.GET_CATEGORIES_SUCCESS: // maybe not needed
      return {...state, categories: action.payload};
    case volunteerConstants.ONVIEW_VOLUNTEER:
      return {...state, viewId: action.payload};
    case volunteerConstants.ONVIEW_DONE_VOLUNTEER:
      return {...state, viewId: undefined};
    default:
      return state;
  }
}