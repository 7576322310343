import {db} from "../base";
import {normalizedStringCompare} from "./stringHelpers";


export async function getFirebaseDocumentById(collectionName, documentId, useDocDataId) {
  const docSnapshot = await db
    .collection(collectionName)
    .doc(documentId)
    .get();
  
  if (!docSnapshot.exists) {
    console.log(`Firestore Document with ID '${documentId}' in Collection '${collectionName}' does not exist.`);
    return null;
  }
  
  return {
    ...docSnapshot.data(),
    id: useDocDataId ? docSnapshot.data().id : docSnapshot.id
  };
}


export async function getFirestoreCollection(collectionName, queryFiltersWhere, sortByKey, useDocDataId) {
  let query = await db.collection(collectionName);
  
  // apply filters if any
  if (queryFiltersWhere && queryFiltersWhere.length && queryFiltersWhere.every(filter => Array.isArray(filter))) {
    
    queryFiltersWhere.forEach(filter => {
      // destructuring filter["property","==", "value"]
      const [property, operator, match] = filter;
      // noinspection JSCheckFunctionSignatures
      query = query.where(property, operator, match);
    });
    
  }
  
  // get the data snapshot from with the query
  const snapshot = await query.get();
  
  // parse the firestore snapshot into an array of objects
  const collectionData = snapshot.docs.map(docRef => ({
    ...docRef.data(),
    id: useDocDataId ? docRef.data().id : docRef.id
  }));
  
  // sort if required to
  if (sortByKey) {
    sortByKey = typeof sortByKey === "string"
      ? sortByKey
      : "name.fr";
    
    const resolveSortByNestedKeyString = (path, obj, separator = ".") => {
      const properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    };
    
    collectionData.sort((a, b) => normalizedStringCompare(
      resolveSortByNestedKeyString(sortByKey, b),
      resolveSortByNestedKeyString(sortByKey, a)
    ));
  }
  
  return collectionData;
}


export function listenFirestoreCollection(collectionName, queryFiltersWhere, sortByKey, useDocDataId, setCollectionData) {
  
  let query = db.collection(collectionName);
  
  // apply filters if any
  if (queryFiltersWhere && queryFiltersWhere.length && queryFiltersWhere.every(filter => Array.isArray(filter))) {
    
    queryFiltersWhere.forEach(filter => {
      // destructuring filter["property","==", "value"]
      const [property, operator, match] = filter;
      // noinspection JSCheckFunctionSignatures
      query = query.where(property, operator, match);
    });
    
  }
  
  // get the data snapshot from with the query
  const cleanupListener = query.onSnapshot((snapshot) => {
    
    // parse the firestore snapshot into an array of objects
    const collectionData = snapshot.docs.map(docRef => ({
      ...docRef.data(),
      id: useDocDataId ? docRef.data().id : docRef.id
    }));
    
    // sort if required to
    if (sortByKey) {
      sortByKey = typeof sortByKey === "string"
        ? sortByKey
        : "name.fr";
      
      const resolveSortByNestedKeyString = (path, obj, separator = ".") => {
        const properties = Array.isArray(path) ? path : path.split(separator);
        return properties.reduce((prev, curr) => prev && prev[curr], obj);
      };
      
      collectionData.sort((a, b) => normalizedStringCompare(
        resolveSortByNestedKeyString(sortByKey, b),
        resolveSortByNestedKeyString(sortByKey, a)
      ));
    }
    
    setCollectionData(
      collectionData
    );
  });
  
  
  return cleanupListener;
}


export function listenFirestoreDocument(collectionName, documentId, useDocDataId, setDocumentData) {
  
  let docRef = db.collection(collectionName).doc(documentId);
  
  // get the data snapshot from the doc ref
  const cleanupListener = docRef.onSnapshot((doc) => {
    setDocumentData({
      ...doc.data(),
      id: useDocDataId ? doc.data().id : doc.id
    });
  });
  
  
  return cleanupListener;
}
