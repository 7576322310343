import {getDistance, isValidCoordinate} from "geolib";
import {round} from "lodash";

const googleMapsApiKey = "AIzaSyC-IAITMfb1HzAogIii6Y7mRhKYEIIwgRY";


function getDistanceStraightLineInKM({fromLocationCoords, destinationLocationCoords}) {
  // fromLocationCoords = {latitude: latitude1, longitude: longitude1};
  // destinationLocationCoords = {latitude: latitude2, longitude: longitude2};
  
  if (!isValidCoordinate(fromLocationCoords))
    return "Err: fromCords should be an object {latitude: latitude1, longitude: longitude1}";
  if (!isValidCoordinate(destinationLocationCoords))
    return "Err: destinationLocationCoords should be an object {latitude: latitude1, longitude: longitude1}";
  
  const distanceMeters = getDistance(fromLocationCoords, destinationLocationCoords);
  const km = distanceMeters / 1000;
  
  return round(km, 1);
  // Return the distance in Kilometers between two points on the map.
  // This is not driving distance.
  // This is "birds-eye-view" distance which means it's a straight line calculation.
  // Therefore, the accuracy can be misleading if comparing with a Google Map driving distance calculation.
}


function getLocationArray(latitude, longitude) {
  // WARNING:
  // The order of the geolocation array should always be in this standard order: [latitude, longitude]
  return [latitude, longitude];
}


export {googleMapsApiKey, getDistanceStraightLineInKM, getLocationArray};
