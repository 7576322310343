import uniqid from "uniqid";

import {eventConstants} from "../constants/actionTypes";
import {db, fieldValue, store} from "../../base";
import {normalizedStringCompare} from "../../helpers/stringHelpers";
import {createNotification, getNotificationsByType} from "./notificationAction";
import {getImageFileBlob, getLogoAndImagesFromCloudFirestore} from "../../helpers/imagesHelpers";
import {getFirestoreCollection} from "../../helpers/firestoreHelpers";

export const getEventsSuccess = (events) => {
  return {
    type: eventConstants.GET_EVENTS_SUCCESS,
    payload: events
  };
};

const getEventsFailure = (err) => {
  return {
    type: eventConstants.GET_EVENTS_FAILURE,
    payload: err
  };
};

export const removeEventDetails = () => ({type: eventConstants.REMOVE_EVENT_DETAILS});

export const removeOrganizationDetails = () => ({type: eventConstants.REMOVE_ORGANIZATION_DETAILS});

const getEventRequest = () => ({type: eventConstants.GET_EVENT_REQUEST});

const getEventSuccess = (event, eventImages, eventLogo) => {
  return {
    type: eventConstants.GET_EVENT_SUCCESS,
    payload: {
      event,
      eventImages,
      eventLogo
    }
  };
};

const getEventFailure = (err) => {
  return {
    type: eventConstants.GET_EVENT_FAILURE,
    payload: err
  };
};

export const addNewPhoto = (data) => {
  return {
    type: eventConstants.ADD_NEW_IMAGE,
    payload: data
  };
};

const getOrganizationsNameSuccess = (data) => {
  return {
    type: eventConstants.GET_ORGANIZATIONS_NAME_SUCCESS,
    payload: data
  };
};

const getOrganizationNameByIdSuccess = (data) => {
  return {
    type: eventConstants.GET_ORGANIZATION_NAME_BU_ID_SUCCESS,
    payload: data
  };
};

const getCategoriesSuccess = (data) => {
  return {
    type: eventConstants.GET_CATEGORIES_SUCCESS,
    payload: data
  };
};

export const deleteEventImage = (name) => {
  return {
    type: eventConstants.DELETE_IMAGE,
    payload: name
  };
};

// export const saveEvent = (event) => {
//     return {
//         type: eventConstants.SAVE_EVENT_MODEL,
//         payload: event
//     };
// };

// export const saveNotification = (notification) => {
//     return {
//         type: eventConstants.SAVE_NOTIFICATION_MODEL,
//         payload: notification
//     }
// }

const getEventNotificationsSuccess = (data) => {
  return {
    type: eventConstants.GET_EVENT_NOTIFICATIONS_SUCCESS,
    payload: data
  };
};


export const deleteEventSuccess = () => {
  return {
    type: eventConstants.DELETE_EVENT_SUCCESS
  };
};

export const deleteEventFailure = (err) => {
  return {
    type: eventConstants.DELETE_EVENT_FAILURE,
    payload: err
  };
};

export const removeNotifications = () => {
  return {
    type: eventConstants.REMOVE_NOTIFICATIONS
  };
};

export const setFilterValue = (value) => {
  return {
    type: eventConstants.SET_FILTER_VALUE,
    payload: value
  };
};

export const clearEventFilter = () => {
  return {
    type: eventConstants.CLEAR_EVENT_FILTER
  };
};

export const getStaffSuccess = (data) => {
  return {
    type: eventConstants.GET_STAFF_SUCCESS,
    payload: data
  };
};

export const getStaffRequest = () => {
  return {
    type: eventConstants.GET_STAFF_REQUEST
  };
};

export const changeEventStatus = (event, filterOrganizationId) => dispatch => {
  return db
    .collection("Event")
    .doc(event.id)
    .set({isAvailable: !event.isAvailable}, {merge: true})
    .then(() => dispatch(getEvents(filterOrganizationId)))
    .catch(error => console.error("Error writing document: ", error));
};

export const removeEventImages = (imageFileNames, eventId) => {
  if (imageFileNames) {
    return imageFileNames.forEach(fileName => {
      store
        .ref()
        .child(`images/event/${eventId}/${fileName}`)
        .delete()
        .then(() => console.log("remove success"))
        .catch((err) => {
        });
    });
  }
};

export const removeEventLogo = (logoFileName, eventId) => {
  return store
    .ref()
    .child(`images/event/${eventId}/logo/${logoFileName}`)
    .delete()
    .then(() => console.log("remove success"))
    .catch((err) => {
    });
  
};

const getEventsByIdOrganizationSuccess = (eventslist, filterOrganizationId) => {
  return {
    type: eventConstants.GET_LIST_EVENTS_BY_ORGANIZATION_SUCCESS,
    payload: {
      eventslist: eventslist, filterOrganizationId: filterOrganizationId
    }
  };
};

export const setFilterOrganizationId = filterOrganizationId => {
  return {
    type: eventConstants.SET_FILTER_ORGANIZATION_ID,
    payload: filterOrganizationId
  };
};

const getEventsByIdOrganizationFailure = (err) => {
  return {
    type: eventConstants.GET_LIST_EVENTS_BY_ORGANIZATION_FAILTURE,
    payload: err
  };
};

export const changeCurrentPage = currentPage => {
  return {
    type: eventConstants.CHANGE_EVENT_CURRENT_PAGE,
    payload: currentPage
  };
};

export const changeRowsPerPage = rowsPerPage => {
  return {
    type: eventConstants.CHANGE_EVENT_ROWS_PER_PAGE,
    payload: rowsPerPage
  };
};

export const getEventsRequest = () => {
  return {
    type: eventConstants.GET_EVENTS_REQUEST
  };
};

export const getEventsByIdOrganizationRequest = () => {
  return {
    type: eventConstants.GET_LIST_EVENTS_BY_ORGANIZATION_REQUEST
  };
};

export const getEvents = (idOrganization = null, auth) => dispatch => {
  if (idOrganization) {
    dispatch(getEventsByIdOrganization(idOrganization, auth));
  } else {
    dispatch(getAllEvents(auth));
  }
};

export const getEventsByIdOrganization = (organizationId, auth) => async dispatch => {
  console.log("getEventsByIdOrganization()");
  try {
    dispatch(getEventsByIdOrganizationRequest());
    
    const [events, organizationNames] = await Promise.all([
      getFirestoreCollection("Event", [["organizationId", "==", organizationId]]),
      getOrganizationsName()
    ]);
    
    const eventsList = await Promise.all(events.map(async event => {
      
      const [logo, images] = await getLogoAndImagesFromCloudFirestore(
        event.logo,
        event.images,
        `event/${event.id}`
      );
      
      const staff = await getFirestoreCollection("Staff", [
          ["organizationId", "==", event.organizationId],
          ["isAvailable", "==", true],
        ]
      );
      
      const org = organizationNames.find(item => item.id === event.organizationId);
      
      return {
        ...event,
        logo,
        images,
        staff,
        organizationName: org ? org.name : "null"
      };
      
    }));
    
    const activeOrganizations = organizationNames.filter(org => org.isActive);
    
    dispatch(getEventsByIdOrganizationSuccess(eventsList, organizationId));
    dispatch(getOrganizationsNameSuccess(activeOrganizations));
  } catch (e) {
    dispatch(getEventsByIdOrganizationFailure(e));
  }
};

export const getActiveEvents = () => async dispatch => {
  console.log("getActiveEvents()");
  try {
    dispatch(getEventsRequest());
    
    const [events, organizationNames] = await Promise.all([
      getFirestoreCollection("Event", [["isAvailable", "==", true]]),
      getOrganizationsName()
    ]);
    
    const eventsList = await Promise.all(events.map(async event => {
      
      const [logo, images] = await getLogoAndImagesFromCloudFirestore(
        event.logo,
        event.images,
        `event/${event.id}`
      );
      
      const staff = await getFirestoreCollection("Staff", [
          ["organizationId", "==", event.organizationId],
          ["isAvailable", "==", true],
        ]
      );
      
      const org = organizationNames.find(item => item.id === event.organizationId);
      
      return {
        ...event,
        logo,
        images,
        staff,
        organizationName: org ? org.name : "null"
      };
      
    }));
    
    const activeOrganizations = organizationNames.filter(org => org.isActive);
    
    dispatch(getEventsSuccess(eventsList));
    dispatch(getOrganizationsNameSuccess(activeOrganizations));
  } catch (e) {
    dispatch(getEventsFailure(e));
  }
};

export const getAllEvents = (auth) => async dispatch => {
  console.log("getAllEvents()");
  try {
    dispatch(getEventsRequest());
    
    const [events, organizationNames] = await Promise.all([
      getFirestoreCollection("Event"),
      getOrganizationsName()
    ]);
    
    const eventsList = await Promise.all(events.map(async event => {
      
      const [logo, images] = await getLogoAndImagesFromCloudFirestore(
        event.logo,
        event.images,
        `event/${event.id}`
      );
      
      const staff = await getFirestoreCollection("Staff", [
          ["organizationId", "==", event.organizationId],
          ["isAvailable", "==", true],
        ]
      );
      
      const org = organizationNames.find(item => item.id === event.organizationId);
      
      return {
        ...event,
        logo,
        images,
        staff,
        organizationName: org ? org.name : "null"
      };
      
    }));
    
    const activeOrganizations = organizationNames.filter(org => org.isActive);
    
    dispatch(getEventsSuccess(eventsList));
    dispatch(getOrganizationsNameSuccess(activeOrganizations));
  } catch (e) {
    dispatch(getEventsFailure(e));
  }
};

export const getOrganizationsName = async () => {
  try {
    const response = await db
      .collection("Organization")
      .orderBy("name")
      .get();
    
    return response.docs.map(doc =>
      ({
        name: doc.data().name.fr,
        id: doc.id,
        category: doc.data().category,
        isActive: doc.data().isActive,
      })
    );
  } catch (e) {
  }
};

export const getEvent = (eventId) => {
  return async dispatch => {
    dispatch(getEventRequest());
    try {
      const eventResponse = await db
        .collection("Event")
        .doc(eventId)
        .get();
      
      if (!eventResponse.exists) throw new Error();
      const data = eventResponse.data();
      const startDate = data.startDate.toDate();
      const endDate = data.endDate.toDate();
      
      const mapToImage = async (imageName, type) => {
        try {
          let url;
          if (type === "logo") {
            url = await store.ref().child(`images/event/${eventId}/logo/${imageName}`).getDownloadURL();
          } else {
            url = await store.ref().child(`images/event/${eventId}/${imageName}`).getDownloadURL();
          }
          return {key: imageName, url};
        } catch (e) {
        }
      };
      const urlResponse = await Promise.all(data.images.map(mapToImage));
      const urlLogo = await mapToImage(data.logo, "logo");
      
      const event = {...data, startDate, endDate};
      dispatch(getEventSuccess(event, urlResponse, urlLogo));
    } catch (e) {
      dispatch(getEventFailure(e));
    }
  };
};

export const deleteEvent = (event, organizationId = null) => async dispatch => {
  try {
    await db
      .collection("Event")
      .doc(event.id)
      .delete();
    
    await removeEventLogo(event.logo[0].name, event.id);
    await removeEventImages(event.images.map(img => img.name), event.id);
    
    await dispatch(deleteEventSuccess());
    await dispatch(getEvents(organizationId));
  } catch (err) {
    await dispatch(deleteEventFailure(err));
  }
};

export const createEvent = async (event, notification, handleAfterSavingDone) => {
  const eventRef = db.collection("Event").doc();
  
  const uploadedLogoFilename = uploadLogo(event.logo.filter(img => !img.isDelete)[0].file, eventRef.id);
  const uploadedImagesFilenames = uploadImages(event.images.filter(img => !img.isDelete).map(img => img.file), eventRef.id);
  
  const currentEvent = {
    ...event,
    logo: uploadedLogoFilename,
    images: uploadedImagesFilenames
  };
  
  if (notification.sendPublicPushNotification) {
    notification.type = "eventCreated";
    await createEventModel(currentEvent, eventRef.id, handleAfterSavingDone);
    await createNotification(currentEvent, {eventId: eventRef.id}, notification);
  } else {
    await createEventModel(currentEvent, eventRef.id, handleAfterSavingDone);
  }
};

// export const updateEvent = async (event, eventImages, eventId, eventLogo, notification) => {
export const updateEvent = async (event, eventId, notification, handleAfterSavingDone) => {
  
  const imagesToDelete = event.images.filter(img => img.isDelete);
  const imagesToUpload = event.images.filter(img => !img.isDelete && !img.isStorage && img.file instanceof Blob);
  const logoToDelete = event.logo.filter(img => img.isDelete);
  const logoToUpload = event.logo.filter(img => !img.isDelete && img.file instanceof Blob);
  
  // Remove images to delete from Firebase Cloud storage
  if (imagesToDelete.length > 0) {
    await removeEventImages(imagesToDelete.map(img => img.name), eventId);
  }
  
  // Add new images in Firebase Cloud storage
  let uploadedImagesFileNames;
  if (imagesToUpload.length > 0) {
    uploadedImagesFileNames = uploadImages(imagesToUpload.map(img => img.file), eventId);
  }
  
  // Remove logo to delete from Firebase Cloud storage
  if (logoToDelete.length > 0) {
    await removeEventLogo(logoToDelete[0].name, eventId);
  }
  
  // Update logo in Firebase Cloud storage if needed
  let uploadedLogoFileName;
  if (logoToUpload.length > 0) {
    uploadedLogoFileName = uploadLogo(logoToUpload[0].file, eventId);
  }
  
  const updatedLogoFileName = logoToUpload.length > 0 ? uploadedLogoFileName : event.logo[0].name;
  const currentImages = event.images.filter(img => !img.isDelete && img.isStorage && img.name).map(img => img.name);
  const updatedImageFileNamesArray = imagesToUpload.length > 0 ? currentImages.concat(uploadedImagesFileNames) : currentImages;
  
  const currentEventModel = {
    ...event,
    logo: updatedLogoFileName,
    images: updatedImageFileNamesArray
  };
  
  await updateEventModel(currentEventModel, eventId, handleAfterSavingDone);
  
  if (notification.sendPublicPushNotification) {
    notification.type = "eventModified";
    await createNotification(currentEventModel, {eventId: eventId}, notification);
  }
};

export const duplicateEvent = async (event, sourceEventId, notification, handleAfterSavingDone) => {
  const cloudStorageFolder = `event/${sourceEventId}`;
  
  const duplicateLogo = event.logo.filter(img => !img.isDelete).map(img => img.file ? img.file : img)[0];
  const duplicateImages = event.images.filter(img => !img.isDelete).map(img => img.file ? img.file : img);
  
  // get blob for the event logo
  event.logo = [{
    ...duplicateLogo,
    file: await getImageFileBlob(duplicateLogo, "logo", cloudStorageFolder)
  }];
  
  // get blobs for the event images
  const imageBlobPromises = duplicateImages.map(async img => ({
    ...img,
    file: await getImageFileBlob(img, "image", cloudStorageFolder)
  }));
  event.images = await Promise.all(imageBlobPromises);
  
  // Now that we have converted images to Blobs, we can use the createEvent function normally.
  await createEvent(event, notification, handleAfterSavingDone);
};

const createEventModel = (event, eventId, handleAfterSavingDone) => {
  return db
    .collection("Event")
    .doc(eventId)
    .set(event)
    .then(result => {
      handleAfterSavingDone && handleAfterSavingDone();
    })
    .catch(error => console.error("Error writing document: ", error));
  
};

const updateEventModel = (event, id, handleAfterSavingDone) => {
  return db
    .collection("Event")
    .doc(id)
    .set(event, {merge: true})
    .then((result) => {
      handleAfterSavingDone && handleAfterSavingDone();
    })
    .catch(error => console.error("Error writing document: ", error));
};

const uploadImages = (images, eventId) => {
  const newImagesFileNames = [];
  if (images) {
    images.forEach(img => {
      const name = uniqid(undefined, ".png");
      newImagesFileNames.push(name);
      store
        .ref()
        .child(`images/event/${eventId}/${name}`)
        .put(img)
        .then(() => console.log("images uploaded"))
        .catch(error => console.error("Error uploading file: ", error));
    });
  }
  return newImagesFileNames;
};

const uploadLogo = (image, eventId) => {
  const name = uniqid(undefined, ".png");
  store
    .ref()
    .child(`images/event/${eventId}/logo/${name}`)
    .put(image)
    .then(() => console.log("images uploaded"))
    .catch(error => console.error("Error uploading file: ", error));
  return name;
};

export const getActiveOrganizationNames = () => dispatch => {
  return db
    .collection("Organization")
    .where("isActive", "==", true)
    .where("isPending", "==", false)
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => ({
        name: doc.data().name.fr,
        category: doc.data().category,
        id: doc.id,
      }));
      data.sort((a, b) => normalizedStringCompare(b.name, a.name));
      dispatch(getOrganizationsNameSuccess(data));
    })
    .catch(err => {
    });
};

export const getAllOrganizationNames = () => dispatch => {
  return db
    .collection("Organization")
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => ({
        name: doc.data().name.fr,
        id: doc.id,
        category: doc.data().category
      }));
      data.sort((a, b) => normalizedStringCompare(b.name, a.name));
      dispatch(getOrganizationsNameSuccess(data));
    })
    .catch(err => {
    });
};

export const getOrganizationById = (organizationId) => dispatch => {
  return db
    .collection("Organization")
    .doc(organizationId)
    .get()
    .then(querySnapshot => {
      const data = {
        name: querySnapshot.data().name.fr,
        id: querySnapshot.id
      };
      dispatch(getOrganizationNameByIdSuccess(data));
    })
    .catch(err => {
    });
};

export const getCategories = () => dispatch => {
  return db
    .collection("Category")
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => ({...doc.data()}));
      var sorted = data.sort(function (a, b) {
        return a.name.fr.localeCompare(b.name.fr);
      });
      dispatch(getCategoriesSuccess(sorted));
    })
    .catch(err => {
    });
};


export const getEventNotifications = (eventId) => dispatch => {
  getNotificationsByType({eventId}).then((eventNotifications) => {
    dispatch(getEventNotificationsSuccess(eventNotifications));
  });
};

export const changeOrder = (sortName) => {
  return {
    type: eventConstants.CHANGE_EVENT_ORDER,
    payload: sortName
  };
};

export const changeOrderBy = (sortName) => {
  return {
    type: eventConstants.CHANGE_EVENT_ORDERBY,
    payload: sortName
  };
};

export const onViewing = (data) => {
  return {
    type: eventConstants.ONVIEW_EVENT,
    payload: data
  };
};

export const onDoneViewing = () => {
  return {
    type: eventConstants.ONVIEW_DONE_EVENT,
  };
};

export const getBasketRequest = () => {
  return {
    type: eventConstants.GET_BASKET_REQUEST,
  };
};

export const getBasketSuccess = (data) => {
  return {
    type: eventConstants.GET_BASKET_SUCCESS,
    payload: data
  };
};

export const getBasket = (id) => dispatch => {
  getBasketRequest();
  return db
    .collection("Basket")
    .where("creatorOrgId", "==", id)
    //.where("status", "!=", "archived")
    .get()
    .then(async (querySnapshot) => {
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        client: []
      }));
      
      await Promise.all(data.map(async (element) => {
        await db
          .collection("Client")
          .doc(element.clientId)
          .get()
          .then((querySnapshot) => {
            const fdata = querySnapshot.data();
            element.client = fdata;
          })
          .catch((err) => {
            console.log("errr: ", err);
          });
        
        await db
          .collection("ReferralItem")
          .where("basketId", "==", element.id)
          .get()
          .then((querySnapshot) => {
            const rdata = querySnapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            }));
            element.referral = rdata;
          })
          .catch((err) => {
            console.log("errr: ", err);
          });
      }));
      dispatch(getBasketSuccess(data));
    })
    .catch((err) => {
    });
};

export const createBasket = (data) => dispatch => {
  return db
    .collection("Client")
    .add(data.clientData)
    .then(async (docRef) => {
      data.basketData.clientId = docRef.id;
      await db
        .collection("Basket")
        .add(data.basketData)
        .then((docRef) => {
          if (data && data.ReferralData) {
            data.ReferralData.basketId = docRef.id;
            dispatch(addReferral(data.ReferralData, data.auth));
          }
          
          if (data.type === "volOp") {
            let addToBasketData = {
              id: docRef.id,
              currVol: data.currId,
              organizationId: data.auth.organizationId,
            };
            dispatch(addToBasketVol(addToBasketData));
          } else if (data.type === "service") {
            let addToBasketData = {
              id: docRef.id,
              currService: data.currId,
              organizationId: data.auth.organizationId,
            };
            dispatch(addToBasketService(addToBasketData));
          } else if (data.type === "event") {
            let addToBasketData = {
              id: docRef.id,
              currEvent: data.currId,
              organizationId: data.auth.organizationId,
            };
            dispatch(addToBasket(addToBasketData));
          } else if (data.type === "org") {
            let addToBasketData = {
              id: docRef.id,
              currOrg: data.currId,
              organizationId: data.auth.organizationId,
            };
            dispatch(addToBasketOrg(addToBasketData));
          }
        })
        .catch(error => console.error("created basket err: ", error));
    })
    .catch(error => console.error("created client err: ", error));
};

export const addToBasket = (data) => dispatch => {
  return db
    .collection("Basket")
    .doc(data.id)
    .update({
      //comment: data.comment,
      eventList: fieldValue.arrayUnion(data.currEvent)
    })
    .then(() => dispatch(getBasket(data.organizationId)))
    .catch(error => console.error("Add to basket err: ", error));
};

export const addToBasketOrg = (data) => dispatch => {
  return db
    .collection("Basket")
    .doc(data.id)
    .update({
      //comment: data.comment,
      organizationList: fieldValue.arrayUnion(data.currOrg)
    })
    .then(() => dispatch(getBasket(data.organizationId)))
    .catch(error => console.error("Add to basket org err: ", error));
};

export const addToBasketVol = (data) => dispatch => {
  return db
    .collection("Basket")
    .doc(data.id)
    .update({
      //comment: data.comment,
      volunteerOpportunityList: fieldValue.arrayUnion(data.currVol)
    })
    .then(() => dispatch(getBasket(data.organizationId)))
    .catch(error => console.error("Add to basket vol err: ", error));
};

export const addToBasketService = (data) => dispatch => {
  return db
    .collection("Basket")
    .doc(data.id)
    .update({
      serviceList: fieldValue.arrayUnion(data.currService)
    })
    .then(() => dispatch(getBasket(data.organizationId)))
    .catch(error => console.error("Add to basket service error: ", error));
};

export const getStaffs = (orgId) => dispatch => {
  dispatch(getStaffRequest);
  return db
    .collection("Staff")
    .where("organizationId", "==", orgId)
    .where("isAvailable", "==", true)
    .orderBy("firstName", "asc")
    .get()
    .then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
      
      dispatch(getStaffSuccess(data));
    })
    .catch((err) => {
      console.log("errr: ", err);
    });
};

export const addReferral = (data, auth) => dispatch => {
  return db
    .collection("ReferralItem")
    .add(data)
    .then(() => dispatch(getBasket(auth.organizationId)))
    .catch(error => console.error("addReferral err: ", error));
};

export const editReferral = (data, auth, id) => dispatch => {
  return db
    .collection("ReferralItem")
    .doc(id)
    .update(data)
    .then(() => dispatch(getBasket(data.organizationId)))
    .catch(error => console.error("editReferral err: ", error));
};
