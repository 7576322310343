// Firebase configs and initializers
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

console.log("REACT_APP_ENV = " + process.env.REACT_APP_ENV);

const firebaseConfigs = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = firebase.initializeApp(firebaseConfigs);

const db = firebase.firestore();
const auth = firebase.auth();
const store = firebase.storage();

const fieldValue = firebase.firestore.FieldValue;
const fieldPath = firebase.firestore.FieldPath;
const timestamp = firebase.firestore.Timestamp;

if (process.env.REACT_APP_ENV === "firebase-local-emulators") {
  db.useEmulator("localhost", 8080);
  auth.useEmulator("http://localhost:9099");
  store.useEmulator("localhost", 9199);
}

// WARNING: This is a temporary reference used only
// when required to create a new Staff user account
// from the currently connected Firebase Auth User.
// The reason for this is that you cannot create
// a new Auth User without being disconnected from the current user.
// So this temporary App will only be used to create the new Auth user.
let tempFirebaseAuthenticationServiceApp = null;
const getTempFirebaseAuthenticationServiceApp = () => {
  if (firebase.apps.length === 1) {
    // assign the value only when the real firebase app has been initialized and the temp app has not yet been initialized.
    tempFirebaseAuthenticationServiceApp = firebase.initializeApp(firebaseConfigs, "temporaryFirebaseAuthenticationServiceApp");
    if (process.env.REACT_APP_ENV === "firebase-local-emulators") {
      const auth = tempFirebaseAuthenticationServiceApp.auth();
      auth.useEmulator("http://localhost:9099");
    }
  }
  return tempFirebaseAuthenticationServiceApp;
};


export {
  app,
  db,
  auth,
  store,
  fieldValue,
  fieldPath,
  timestamp,
  getTempFirebaseAuthenticationServiceApp,
};
